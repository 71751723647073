import React, { useEffect, useState } from 'react';
import './PrivateNavbar.scss';
import type { MenuProps } from 'antd';
import { Avatar, Dropdown, Typography, message, Badge } from 'antd';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { getToken, removeToken } from '../../../utils/authHelpers';
import { randomVector } from '../../../utils/randomVector';
import MobileMenuDrawer from '../../../drawers/MobileMenu/MobileMenuDrawer';
import { signOut } from 'firebase/auth';
import { onSnapshot, collection, query } from 'firebase/firestore';
import { auth, db } from '../../../utils/firebase';
import { useSubUser } from '../../../utils/useSubUser';

const PrivateNavbar: React.FC = () => {
    const { user }: any = useSubUser();
    const [profileItems, setProfileItems] = useState<MenuProps['items']>([]);
    const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
    const [unreadMessages, setUnreadMessages] = useState<number>(0);
    const navigate = useNavigate();

    // Updated useEffect to match inbox page structure
    useEffect(() => {
        if (!user?.uid) return;
        let unsubscribe: any;

        try {
            const chatsRef = collection(db, 'Chats', user.uid, 'MyChats');
            const myChatsQuery = query(chatsRef);

            unsubscribe = onSnapshot(myChatsQuery, (querySnapshot) => {
                try {
                    if (querySnapshot.empty) {
                        setUnreadMessages(0);
                        return;
                    }

                    // Count unread messages
                    const unreadCount = querySnapshot.docs.reduce((count, doc) => {
                        const chatData = doc.data();
                        return chatData.isRead === false ? count + 1 : count;
                    }, 0);

                    setUnreadMessages(unreadCount);
                } catch (error) {
                    console.error('Error processing unread messages:', error);
                    setUnreadMessages(0);
                }
            });
        } catch (error) {
            console.error('Error setting up unread messages listener:', error);
            setUnreadMessages(0);
        }

        return () => {
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [user?.uid]);

    const loadDynamicNavItems = () => {
        if (!user) return;
        let profileItemsDynamic: MenuProps['items'] = [
            {
                label: 'Profile',
                key: 'profile',
                icon: <i className="ri-settings-2-line"></i>,
                onClick: () => navigate(`/profile`)
            }
        ];

        if (!user.isBuyer) {
            profileItemsDynamic.push({
                label: 'Dashboard',
                key: 'dashboard',
                icon: <i className="ri-dashboard-line"></i>,
                onClick: () => navigate(`/dashboard`)
            });
        }

        profileItemsDynamic.push({
            label: 'Logout',
            key: 'logout',
            icon: <i className="ri-logout-circle-line"></i>,
            onClick: () => logout()
        });
        setProfileItems(profileItemsDynamic);
    };

    useEffect(() => {
        loadDynamicNavItems();
    }, [user]);

    const logout = () => {
        signOut(auth);
        removeToken("privateNavbar");
        message.success('Successfully logged out.');
        navigate('/login');
    };

    const renderNavigationItems = () => {
        if (!user) return;
        return (
            <div className="navCenter">
                <NavLink
                    className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
                    to="/home"
                >
                    <i className={`ri-home-5-line ri-xl`}></i>
                    <b>Home</b>
                </NavLink>
                <NavLink
                    className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
                    to="/inbox"
                >
                    <Badge count={unreadMessages} offset={[10, -5]}>
                        <i className={`ri-question-answer-line ri-xl`}></i>
                        <b>Messages</b>
                    </Badge>
                </NavLink>
                {user.isBuyer && (
                    <NavLink
                        className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
                        to="/favorites"
                    >
                        <i className={`ri-heart-2-line ri-xl`}></i>
                        <b>Favourites</b>
                    </NavLink>
                )}
                {!user.isBuyer && (
                    <NavLink
                        className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
                        to="/dashboard"
                    >
                        <i className={`ri-dashboard-line ri-xl`}></i>
                        <b>Dashboard</b>
                    </NavLink>
                )}
                <NavLink
                    className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
                    to="/orders"
                >
                    <i className={`ri-shopping-cart-2-line ri-xl`}></i>
                    <b>Orders</b>
                </NavLink>
            </div>
        );
    };

    return (
        <div className="privateNavigation">
            <div className="navLogo">
                <Link to="/home">
                    MY BREEDERS STORE
                </Link>
            </div>
            {renderNavigationItems()}
            {getToken() !== '' && user && (
                <div className="navProfile">
                    <Dropdown placement="bottomRight" arrow menu={{ items: profileItems }} trigger={['click']}>
                        <span className="navProfileCta">
                            <Avatar
                                src={user.profilePic ?? require(`../../../assets/images/vectors/${randomVector}.png`)}
                            />
                            {user.isBuyer ? (
                                <Typography.Text>
                                    {user.firstName} {user.lastName} {user.name}
                                </Typography.Text>
                            ) : (
                                <Typography.Text>{user.registryName}</Typography.Text>
                            )}
                        </span>
                    </Dropdown>
                </div>
            )}
            <div className="mobileActions">
                <Badge count={unreadMessages} offset={[-8, 0]}>
                    <div className="messageIcon" onClick={() => navigate('/inbox')}>
                        <i className="ri-message-2-line"></i>
                    </div>
                </Badge>
                <div className="menuIcon">
                    <i className="ri-menu-line" onClick={() => setOpenMobileMenu(true)}></i>
                </div>
            </div>
            <MobileMenuDrawer
                opened={openMobileMenu}
                onCancel={() => setOpenMobileMenu(false)}
                onForceCb={() => console.log('')}
            />
        </div>
    );
};

export default PrivateNavbar;