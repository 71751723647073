import React, { useEffect, useState } from 'react';
import './BreederStorefront.scss';
import BreederCard from '../BreederCard/BreederCard';
import { Col, Empty, Row, Select, message } from 'antd';
import BreederCategoryCard from '../BreederCategoryCard/BreederCategoryCard';
import AdvancedBreederStorefrontFilterDrawer from '../../drawers/AdvancedBreederStorefrontFilter/AdvancedBreederStorefrontFilterDrawer';
import { categories } from '../../utils/constant';
import { getToken } from '../../utils/authHelpers';
import { useLocation } from 'react-router-dom';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../utils/firebase';

const Fade = require('react-reveal/Fade');

interface BreederStorefrontProps {
  breederId?: string;
}

type SortByTypes = 'asc' | 'desc' | '';

interface Breed {
  id: string;
  name: string;
}

const useBreeds = () => {
  const [breeds, setBreeds] = useState<Breed[]>([]);
  const [loadingBreeds, setLoadingBreeds] = useState(false);

  const fetchBreeds = async () => {
    setLoadingBreeds(true);
    try {
      const breedsRef = collection(db, 'DogList');
      const querySnapshot = await getDocs(breedsRef);
      const breedsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name || ''
      }));
      setBreeds(breedsData);
    } catch (error) {
      console.error('Error fetching breeds:', error);
      message.error('Failed to load dog breeds');
    } finally {
      setLoadingBreeds(false);
    }
  };

  useEffect(() => {
    fetchBreeds();
  }, []);

  return { breeds, loadingBreeds };
};

const BreederStorefront: React.FC<BreederStorefrontProps> = ({ breederId }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramCategoryId: string | null = queryParams.get('categoryId');
  const [listOfBreeders, setListOfBreeders] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [openAdvancedFilter, setOpenAdvancedFiter] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<number>(2);
  const [sortBy, setSortBy] = useState<SortByTypes>('');
  const [showPrice, setShowPrice] = useState(true);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const { breeds, loadingBreeds } = useBreeds();

  const loadListOfBreeders = async () => {
    try {
      setLoading(true);
      const selectedCollection: string =
        categories.find((item) => item.id === selectedCategory)?.collection ?? '';
      if (selectedCollection === 'pupProfile') {
        setShowPrice(true);
      } else {
        setShowPrice(false);
      }
      const ref = collection(db, selectedCollection);
      let q = query(ref, orderBy('date', 'desc'));

      if (breederId) {
        q = query(ref, where('uid', '==', breederId), orderBy('date', 'desc'));
      }

      const data = await getDocs(q);
      const tmp: any[] = [];
      data.forEach((element) => {
        const data: any = element.data();
        if (!data.isSold) tmp.push({ ...data, id: element.id });
      });
      setListOfBreeders(tmp);
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error(`Something went wrong in getting list of breeders.`);
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadParamsCategoryId = () => {
      if (!paramCategoryId) {
        if (!selectedCategory) {
          setSelectedCategory(0);
        }
        return;
      }
      const parsedCategoryId: number = parseInt(paramCategoryId);
      setSelectedCategory(parsedCategoryId);
    };
    loadParamsCategoryId();
  }, [paramCategoryId, selectedCategory]);

  useEffect(() => {
    loadListOfBreeders();
    // eslint-disable-next-line
  }, [selectedCategory, breederId]);

  const renderListOfBreeders = () => {
    if (!Array.isArray(listOfBreeders) || !listOfBreeders.length) return <Empty />;
    let listOfBreedersData = listOfBreeders.filter((data) => !data.isDeleted);

    // Search functionality
    if (searchValue) {
      const currentCategory = categories.find((item) => item.id === selectedCategory)?.collection ?? '';
      
      // Define searchable fields for each category
      const getSearchableFields = (data: any) => {
        const commonFields = [
          data.name,
          data.dogType,
          data.type,
          data.pupName,
          data.breed,
          data.breedType,
          data.dogBreed,
          data.description,
          data.color,
          data.gender
        ];

        switch(currentCategory) {
          case 'studProfile':
            return [
              ...commonFields,
              data.studName,
              data.bloodline,
              data.registration
            ];
          
          case 'pupProfile':
            return [
              ...commonFields,
              data.litterName,
              data.kennel,
              data.kennelName
            ];
          
          case 'upcomingLitters':
            return [
              ...commonFields,
              data.litterName,
              data.damName,
              data.sireName
            ];
          
          case 'PupForShowCase':
            return [
              ...commonFields,
              data.showName,
              data.kennelName,
              data.achievements
            ];
          
          default:
            return commonFields;
        }
      };

      listOfBreedersData = listOfBreedersData.filter((data) => {
        // Get all searchable fields for this item
        const searchableFields = getSearchableFields(data).filter(field => field); // Remove null/undefined values
        
        // Check if any field includes the search value
        return searchableFields.some(field => 
          field?.toString().toLowerCase().includes(searchValue.toLowerCase())
        );
      });

      // Debug logs
      console.log('Search Value:', searchValue);
      console.log('Category:', currentCategory);
      console.log('Filtered Results:', listOfBreedersData.length);
      if (listOfBreedersData.length > 0) {
        console.log('Sample Data Fields:', getSearchableFields(listOfBreedersData[0]));
      }
    }

    // Sorting functionality
    if (sortBy) {
      const searchItem = listOfBreedersData.length
        ? listOfBreedersData[0]?.dogType
          ? 'dogType'
          : 'name'
        : 'name';
    
      switch (sortBy as string) {  // Type assertion to string to fix the comparison
        case 'desc':
          listOfBreedersData = listOfBreedersData.sort((a, b) => 
            b[searchItem]?.localeCompare(a[searchItem])
          );
          break;
        case 'asc':
          listOfBreedersData = listOfBreedersData.sort((a, b) => 
            a[searchItem]?.localeCompare(b[searchItem])
          );
          break;
        case 'createdDesc':
          listOfBreedersData = listOfBreedersData.sort((a, b) => 
            (b.date?.seconds || 0) - (a.date?.seconds || 0)
          );
          break;
        case 'createdAsc':
          listOfBreedersData = listOfBreedersData.sort((a, b) => 
            (a.date?.seconds || 0) - (b.date?.seconds || 0)
          );
          break;
        default:
          break;
      }
    }

    return listOfBreedersData.map((data) => {
      const currentCategory = categories.find((item) => item.id === selectedCategory)?.collection ?? '';
      
      // Get the appropriate name field based on category
      let displayName = '';
      switch(currentCategory) {
        case 'studProfile':
          displayName = data.type || data.name;
          break;
        case 'PupForShowCase':
          displayName = data.pupName || data.name;
          break;
        case 'pupProfile':
        case 'upcomingLitters':
          displayName = data.dogType || data.name;
          break;
        default:
          displayName = data.name;
      }

      return (
        <Col
          key={data.id}
          xs={24}
          sm={24}
          md={breederId ? 12 : 8}
          lg={breederId ? 8 : 6}
          xl={breederId ? 8 : 6}
          xxl={breederId ? 8 : 6}
        >
          <Fade>
            <BreederCard
              dadImage={data.dadImage}
              mumImage={data.mumImage}
              showPrice={showPrice}
              id={data.id}
              pedigreePaperDam={data.pedigreePaperDam}
              pedigreePaperSire={data.pedigreePaperSire}
              name={displayName}
              description={data.description}
              category={currentCategory}
              dogType={data.dogType}
              type={data.type}
              pupName={data.pupName}
              previewUrl={
                Array.isArray(data.dogImages) && data.dogImages.length
                  ? data.dogImages[0]
                  : data.dogImage
                  ? data.dogImage
                  : Array.isArray(data.pupsImages) && data.pupsImages.length
                  ? data.pupsImages[0]
                  : Array.isArray(data.pupImages) && data.pupImages.length
                  ? data.pupImages[0]
                  : data.profilePhotoURLs
                  ? data.profilePhotoURLs[0]
                  : ''
              }
              ownerId={data.uid}
              ownerName={data.businessName}
              ownerProfileImageUrl={data.avatar ? data.avatar?.url : ''}
              pricing={data.price ?? data.animalPrice ?? data.dogPrice}
            />
          </Fade>
        </Col>
      );
    });
  };

  const renderCategories = () => {
    if (!Array.isArray(categories) || !categories.length) return;
    // if (!getToken()) return;
    return categories.map((category) =>
      category.collection === 'PupForShowCase' && !pathname.includes('/breeder/') ? null : (
        <Col key={category.id} xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <BreederCategoryCard
            selectedCategoryId={selectedCategory}
            onClick={(categoryId: number) => setSelectedCategory(categoryId)}
            id={category.id}
            name={category.name}
            icon={category.icon}
          />
        </Col>
      )
    );
  };

  return (
    <div className="breederStorefront customLayoutWidth">
      <div className="breederStoreContent">
      <Row
        className={`breederStoreFilterCategory custom-width ${!breederId ? 'relativePos' : ''}`}
        justify={'space-evenly'}
        gutter={[0, 0]}
      >
        {renderCategories()}
      </Row>
        <Row className="breederStorefrontFilters" justify="center">
  <Col xs={24} sm={24} md={18} lg={18} xl={18}>
    <Row gutter={[12, 0]} align="middle" wrap={false}>
      <Col flex="1">
        <Select
          showSearch
          className="searchFilter"
          placeholder="Search for breeds"
          optionFilterProp="children"
          loading={loadingBreeds}
          value={searchValue}
          onChange={(value: string) => setSearchValue(value)}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={breeds.map((breed) => ({
            value: breed.name,
            label: breed.name,
          }))}
          suffixIcon={<i className="ri-search-line"></i>}
          allowClear
        />
      </Col>
      <Col style={{ width: 'auto' }}>
        <Select
          placeholder={<i className="ri-arrow-up-down-line" />}
          className="sortBySelect"
          onChange={(e: any) => setSortBy(e)}
          dropdownMatchSelectWidth={false}
          popupClassName="sort-dropdown"
        >
          <Select.Option value="asc">Sort A to Z</Select.Option>
          <Select.Option value="desc">Sort Z to A</Select.Option>
          <Select.Option value="createdDesc">Newest First</Select.Option>
          <Select.Option value="createdAsc">Oldest First</Select.Option>
        </Select>
      </Col>
    </Row>
  </Col>
</Row>
        <Row
          justify={!Array.isArray(listOfBreeders) || !listOfBreeders.length ? 'center' : 'start'}
          gutter={[24, 24]}
        >
          {loading ? <div>loading</div> : renderListOfBreeders()}
        </Row>
      </div>
      <AdvancedBreederStorefrontFilterDrawer
        opened={openAdvancedFilter}
        onCancel={() => setOpenAdvancedFiter(false)}
        onForceCb={() => console.log('object')}
      />
    </div>
  );
};

export default BreederStorefront;