import React, { useEffect, useState } from 'react';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Button, Col, Empty, Row, Typography, message } from 'antd';
import BreederDocumentCard from '../../components/BreederDocumentCard/BreederDocumentCard';
import ImageGallery from 'react-image-gallery';
import './Animalpage.scss';
import FormatMoney from '../../utils/FormatMoney';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useCopyToClipboard } from 'usehooks-ts';
import AnimalBreederInfo from '../../components/AnimalBreederInfo/AnimalBreederInfo';
import ContactBreederPopup from '../../popups/ContactBreeder/ContactBreederPopup';
import CheckoutDrawer from '../../drawers/Checkout/CheckoutDrawer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { categories } from '../../utils/constant';
import { Helmet } from 'react-helmet';
import appImage from '../../assets/images/appImage.png';
import {
	FacebookShareButton,
	PinterestShareButton,
	TelegramShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from 'react-share';

import { randomVector } from '../../utils/randomVector';
import { collection, deleteDoc, doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { useGetUser } from '../../utils/useGetUser';


const Animalpage: React.FC = () => {
	const { user }: any = useGetUser();
	const { id }: any = useParams();
	const [saved, setSaved] = useState<boolean>(false);
	const [value, copy] = useCopyToClipboard();
	const [openContactBreeder, setOpenContactBreeder] = useState<boolean>(false);
	const [openCheckout, setOpenCheckout] = useState<boolean>(false);
	const [animalData, setAnimalData] = useState<any | null>(null);
	const [images, setImages] = useState<any[]>([]);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const paramCategory: string = queryParams.get('category') ?? '';
	const paramRedirectStatus: string = queryParams.get('redirect_status') ?? '';
	const paramSecret: string = queryParams.get('payment_intent_client_secret') ?? '';
	const paramsOrderId: string = queryParams.get('orderId') ?? '';
	const [clientSecret, setClientSecret] = useState<any>('');
	const [newOrderId, setNewOrderId] = useState<any>();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const imageGalleryProps = {
		showNav: false,
		showPlayButton: false,
		showFullscreenButton: false
	};
	const onChange = (key: string) => {
		console.log(key);
	};

	const onSave = async () => {
		if (!id || typeof id !== 'string') return;
		if (!user) return;
		try {
			const collectionRef = collection(db, 'Users', user.uid, 'Favorites');
			const favRef = doc(collectionRef, id);
			await setDoc(favRef, {
				id,
				date: serverTimestamp(),
				user: user.uid,
				category: paramCategory
			});
			message.success('Successfully added to favorites.');
			setSaved(!saved);
		} catch (error) {
			console.log(error);
			message.error(`Failed to add to saved list.`);
		}
	};

	const onUnsave = async () => {
		if (!id || typeof id !== 'string') return;
		if (!user) return;

		try {
			const collectionRef = collection(db, 'Users', user.uid, 'Favorites');
			const favRef = doc(collectionRef, id);
			await deleteDoc(favRef);
			message.success('Successfully removed to favorites.');
			setSaved(!saved);
		} catch (error) {
			message.error(`Failed to remove to saved list.`);
		}
	};

	const onContactBreeder = () => {
		setOpenContactBreeder(true);
	};

	const onBuy = async () => {
		setOpenCheckout(true);
	};

	const onCopyToClipboard = () => {
		copy(window.location.href);
		console.log('copied link ', value);
		message.success('Successfully copied to clipboard.');
	};

	const loadAnimalData = async () => {
		if (!id && !paramCategory) return;
		try {
			const ref = doc(db, paramCategory, id);
			const snapShot = await getDoc(ref);
			const animal: any = snapShot.data();
			if (animal) {
				const imageList =
					Array.isArray(animal.dogImages) && animal.dogImages.length
						? animal.dogImages
						: animal.dogImage
						? [animal.dogImage]
						: Array.isArray(animal.pupsImages) && animal.pupsImages.length
						? animal.pupsImages
						: Array.isArray(animal.pupImages) && animal.pupImages.length
						? animal.pupImages
						: animal.profilePhotoURLs ?? '';

				if (Array.isArray(imageList) && imageList.length) {
					const tmp = imageList.map((data: any) => ({
						thumbnailClass: 'thumbnailAnimalGallery',
						original: data,
						thumbnail: data.formats ? data.formats?.thumbnail?.url : data
					}));
					if (animal?.dadImage) {
						tmp.push({
							thumbnailClass: 'thumbnailAnimalGallery',
							original: animal?.dadImage,
							thumbnail: animal?.dadImage
						});
					}
					if (animal?.mumImage) {
						tmp.push({
							thumbnailClass: 'thumbnailAnimalGallery',
							original: animal?.mumImage,
							thumbnail: animal?.mumImage
						});
					}
					setImages(tmp);
				} else {
					let tmp = [
						{
							thumbnailClass: 'thumbnailAnimalGallery',
							original: require(`../../assets/images/vectors/${randomVector}.png`),
							thumbnail: require(`../../assets/images/vectors/${randomVector}.png`)
						}
					];
					if (animal?.dadImage) {
						tmp = [
							{
								thumbnailClass: 'thumbnailAnimalGallery',
								original: animal?.dadImage,
								thumbnail: animal?.dadImage
							}
						];
					}
					if (animal?.mumImage) {
						tmp.push({
							thumbnailClass: 'thumbnailAnimalGallery',
							original: animal?.mumImage,
							thumbnail: animal?.mumImage
						});
					}
					setImages(tmp);
				}
				const breederRef = doc(db, 'Breeders', animal.uid);
				const snapShot = await getDoc(breederRef);
				const breeder: any = snapShot.data();
				setAnimalData((pre: any) => ({ ...animal, id: ref.id, ...breeder, breeder }));
			}
		} catch (error) {
			console.log(error);
			message.error(`Something wen't wrong in getting breeder details.`);
		}
	};

	useEffect(() => {
		loadAnimalData();
		// eslint-disable-next-line
	}, [id, paramCategory, animalData]);
	useEffect(() => {
		const loadInitValues = () => {
			const cleanedId: any = id;
			if (!user) return;
			if (!Array.isArray(user.favorites) || !user.favorites.length) return;
			setSaved(user.favorites.some((e: any) => e.animal.id === parseInt(cleanedId)));
			let filteredFavorite = user.favorites.filter((e: any) => e.animal.id.toString() === id);
			if (!Array.isArray(filteredFavorite) || !filteredFavorite.length) return;
		};
		loadInitValues();
		// eslint-disable-next-line
	}, [user, id]);

	useEffect(() => {
		if (paramRedirectStatus && paramsOrderId && animalData && !animalData?.isSold && paramSecret) {
			if (paramRedirectStatus === 'succeeded') {
				(async () => {
					try {
						setLoading(true);
						let pupRef = doc(db, 'PupProfile', animalData?.id);
						let snapShot = await getDoc(pupRef);
						if (!snapShot.exists()) {
							pupRef = doc(db, paramCategory, animalData?.id);
							snapShot = await getDoc(pupRef);
						}
						await updateDoc(pupRef, {
							isSold: true
						});
						const orderRef = doc(db, 'Orders', paramsOrderId);
						await updateDoc(orderRef, {
							paymentStatus: 'Paid'
						});
						message.success('successfull');
						setAnimalData((pre: any) => ({ ...pre, isSold: true }));
						setLoading(false);
						navigate('/');
					} catch (error) {
						console.log(error);
						message.error('something went wrong');
						setLoading(false);
					}
				})();
			}
		}
	}, [animalData, paramsOrderId, paramRedirectStatus, paramCategory, paramSecret, navigate]);

	const renderAnimalDetails = () => {
		// let categoryName: string = '';
		if (!animalData || !user) return (
            <div className="animalPage customLayoutWidth">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                    <p style={{ fontSize: '24px', textAlign: 'center', marginBottom: '20px' }}>
                        You can't view this because you are either not a member or not signed in.
                    </p>
				<Button type="primary" href="/register" style={{ backgroundColor: '#3c3a3b', color: 'white', fontWeight: 'bold', margin: '10px'}}>Create account</Button>
                <Button type="primary" href="/login" style={{ margin: '10px' }}>Log in</Button>
                </div>
            </div>
        );


		// if (Array.isArray(animalData?.categories) && animalData?.categories.length) {
		// 	categoryName = animalData?.categories[0].name;
		// }
		let docList: any[] = [];
		if (animalData.documents?.length) docList = [...animalData.documents];
		if (animalData.pedigreePaper) docList.push({ name: 'Pedigree Paper', url: animalData.pedigreePaper });
		if (animalData.pedigreepaper) docList.push({ name: 'Pedigree Paper', url: animalData.pedigreepaper });
		if (animalData.pedigreePaperDam) docList.push({ name: 'Pedigree Paper Dam', url: animalData.pedigreePaperDam });
		if (animalData.pedigreepaperdam) docList.push({ name: 'Pedigree Paper Dam', url: animalData.pedigreepaperdam });
		if (animalData.pedigreePaperSire)
			docList.push({ name: 'Pedigree Paper Sire', url: animalData.pedigreePaperSire });
		if (animalData.pedigreepapersire)
			docList.push({ name: 'Pedigree Paper Sire', url: animalData.pedigreepapersire });
		if (animalData.pupvideo) docList.push({ name: 'Pup video', url: animalData.pupvideo });
		if (animalData.vetcheck) docList.push({ name: 'Vetcheck', url: animalData.vetcheck });
		if (animalData.vaccinationrecord)
			docList.push({ name: 'Vaccination Record', url: animalData.vaccinationrecord });
		if (animalData.semenAnalysis) docList.push({ name: 'Semen Analysis', url: animalData.semenAnalysis });
		if (animalData.vetChecks) docList.push({ name: 'Vet Checks', url: animalData.vetChecks });
		if (animalData.vaccinationRecords)
			docList.push({ name: 'Vaccination Records', url: animalData.vaccinationRecords });
		const items: TabsProps['items'] = [
			{
				key: '1',
				label: `Details`,
				children:
					animalData?.bio ??
					animalData?.bio ??
					animalData?.aboutDog ??
					animalData?.aboutDog ??
					animalData?.description ??
					'Animal details'
			},
			{
				key: '2',
				label: `Breeder`,
				children: (
					<AnimalBreederInfo
						breederId={animalData?.uid}
						name={animalData?.businessName}
						avatarUrl={
							animalData?.avatar
								? animalData?.avatar.url
								: require(`../../assets/images/vectors/${randomVector}.png`)
						}
						description={animalData?.aboutBusiness}
						address={animalData?.businessAddress}
						verified={animalData?.isVerified}
					/>
				)
			},
			{
				key: '3',
				label: `Documents`,
				children: (
					<Row
						className="breederDocuments"
						align={'middle'}
						justify={!docList?.length || !Array.isArray(docList) ? 'center' : 'start'}
						gutter={[24, 24]}
					>
						{Array.isArray(docList) && docList.length ? (
							docList.map((data: any, index: number) => (
								<Col key={data.id ?? data.url ?? data} xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
									<BreederDocumentCard
										url={data?.fileUrl?.url ?? data.url ?? data}
										animalPage
										title={data.name}
										primary={(index + 1) & 1 ? true : false}
									/>
								</Col>
							))
						) : (
							<Empty />
						)}
					</Row>
				)
			},
			// {
			// 	key: '4',
			// 	label: `Location`,
			// 	children: (
			// 		<div className="animalLocationMap">
			// 			<iframe
			// 				title="animal map"
			// 				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26405.456686327652!2d123.88633469079231!3d10.326304363857895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a999ba46f851e1%3A0x5b057b836416a1a3!2sGoogle%20eBloc%204!5e0!3m2!1sen!2sph!4v1686493307553!5m2!1sen!2sph"
			// 				width="600"
			// 				height="450"
			// 				style={{ border: 0 }}
			// 				allowFullScreen
			// 				loading="lazy"
			// 				referrerPolicy="no-referrer-when-downgrade"
			// 			></iframe>
			// 		</div>
			// 	)
			// },
			{
				key: '5',
				label: `Additional Details`,
				children: <Empty />
			}
		];

		const renderAnimalCta = () => {
			if (!user || !user.isBuyer) return;
			if (!animalData) return;
			if (animalData?.isDeleted) return;
			if (animalData?.isSold) return;

			return (
				<div className="animalCta">
					<Button
						onClick={onBuy}
						className=""
						size="large"
						type="primary"
						loading={loading}
						disabled={loading}
					>
						Make Payment
					</Button>
					<Button
						onClick={onContactBreeder}
						className=""
						size="large"
						type="default"
						loading={loading}
						disabled={loading}
					>
						Contact breeder
					</Button>
				</div>
			);
		};

		return (
			<Row gutter={[24, 24]} className="animalContentRow">
				<Col className="animalPreviewContainer" xs={24} sm={24} md={10} lg={12}>
					<ImageGallery {...imageGalleryProps} items={images} />
				</Col>
				<Col className="animalInfoContainer" xs={24} sm={24} md={14} lg={12}>
					<Typography.Text className="animalCategory">
						{categories.find((item) => item.collection === paramCategory)?.name}
					</Typography.Text>
					<div className="animalNameHeader">
						<div className="animalTitle">
							<PageTitle className="animalName" title={animalData?.type ?? animalData?.dogType ?? animalData?.pupName} />
							{user.isBuyer && (
								<i
									onClick={saved ? onUnsave : onSave}
									className={`ri-heart-${saved ? `fill saved` : `line`} ri-xl`}
								></i>
							)}
						</div>
						<Typography.Title className="animalPricing" level={2}>
							{FormatMoney(animalData?.price ?? animalData?.animalPrice ?? animalData?.dogPrice)}
						</Typography.Title>
					</div>
					<Tabs className="animalInfoTabs" defaultActiveKey="1" items={items} onChange={onChange} />
					{paramCategory === 'pupProfile' ? (animalData?.breeder ? renderAnimalCta() : null) : null}
					<div className="animalShareSocials">
						<Typography.Text>Share on: </Typography.Text>
						<div className="animalSocials">
							<FacebookShareButton
								url={window.location.href}
								title={`Hey, check this out, ${animalData?.name}!`}
								hashtag="myBreederStore"
							>
								<i className="ri-facebook-circle-line ri-xl"></i>
							</FacebookShareButton>
							<TwitterShareButton
								url={window.location.href}
								title={`Hey, check this out, ${animalData?.name}!`}
								hashtags={['VolcanicRetail']}
							>
								<i className="ri-twitter-line ri-xl"></i>
							</TwitterShareButton>
							<TelegramShareButton url={window.location.href} title={animalData?.name}>
								<i className="ri-telegram-line ri-xl"></i>
							</TelegramShareButton>
							<WhatsappShareButton title={animalData?.name} url={window.location.href}>
								<i className="ri-whatsapp-line ri-xl"></i>
							</WhatsappShareButton>
							<PinterestShareButton
								title={animalData?.name}
								url={window.location.href}
								media={
									'https://images.pexels.com/photos/179221/pexels-photo-179221.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
								}
								description={animalData?.bio}
							>
								<i className="ri-pinterest-line ri-xl"></i>
							</PinterestShareButton>
							<i onClick={onCopyToClipboard} className="ri-links-line ri-xl"></i>
						</div>
					</div>
				</Col>
				<ContactBreederPopup
					opened={openContactBreeder}
					breeder={animalData?.breeder}
					onCancel={() => setOpenContactBreeder(false)}
					onForceCb={() => console.log('object')}
				/>
			</Row>
		);
	};
// Replace your return statement at the bottom of the component with this:

return (
	<PrivateLayout className="animalPage customLayoutWidth">
	  {animalData ? (
		<Helmet>
		  <title>
			{`Check out this ${animalData?.pupName || animalData?.type || animalData?.dogType || "Pup"} - My Breeders Store`}
		  </title>
		  <meta 
			name="description" 
			content={(animalData?.bio || animalData?.aboutDog || animalData?.description || "View details about this animal").substring(0, 157)} 
		  />
		  <meta 
			property="og:title" 
			content={`View this ${animalData?.pupName || animalData?.type || animalData?.dogType || "pup"} on My Breeders Store`} 
		  />
		  <meta 
			property="og:description" 
			content={(animalData?.bio || animalData?.aboutDog || animalData?.description || "View details about this animal").substring(0, 157)} 
		  />
		  <meta 
			property="og:image" 
			content={images.length > 0 ? images[0].original : appImage} 
		  />
		  <meta property="twitter:card" content="summary_large_image" />
		</Helmet>
	  ) : (
		<Helmet>
		  <title>Have a look at this Pup - My Breeders Store</title>
		  <meta name="description" content="A pup has been shared with you" />
		  <meta property="og:title" content="View this pup on My Breeders Store" />
		  <meta property="og:description" content="View details about this animal." />
		  <meta property="og:image" content={appImage} />
		  <meta property="twitter:card" content="summary_large_image" />
		</Helmet>
	  )}
	  {renderAnimalDetails()}
	  <CheckoutDrawer
		setClientSecret={setClientSecret}
		clientSecret={clientSecret}
		opened={openCheckout}
		animal={animalData}
		onCancel={() => setOpenCheckout(false)}
		onForceCb={() => console.log('object')}
		setNewOrderId={setNewOrderId}
		newOrderId={newOrderId}
	  />
	</PrivateLayout>
  		);  
	};
	
	export default Animalpage;
